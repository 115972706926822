import React from 'react';
import CoinDetailTab from '../Boltz/CoinDetails/CoinDetailTab';

const CoinDetails = () => {
	return(
		<>
			<CoinDetailTab />
		</>
	)
}
export default CoinDetails;