import React,{Component} from 'react';

class TableData extends Component{
	render(){
		return(
			<>
				
			</>
		)
	}
}
function ArrowUp(){
	return(
		<>
			<span>
				<svg width="64" height="63" viewBox="0 0 64 63" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="0.0517578" width="63.1713" height="63" rx="31.5" fill="#13B440"/>
					<path d="M40.78 32.7207L40.78 32.7207L40.7967 39.6289C40.7968 39.6326 40.7967 39.636 40.7967 39.6392L40.78 32.7207ZM40.78 32.7207C40.778 31.9272 40.1316 31.2854 39.3358 31.2873C38.5401 31.2892 37.8966 31.934 37.8985 32.7275L37.8985 32.7275L37.9068 36.1579L24.6872 22.9741C24.1246 22.413 23.2124 22.413 22.6497 22.9741C22.0871 23.5353 22.0871 24.445 22.6497 25.0061L35.8693 38.1899M40.78 32.7207L35.8693 38.1899M35.8693 38.1899L32.4297 38.1816L32.4296 38.1816C31.634 38.1797 30.9874 38.8214 30.9855 39.615L35.8693 38.1899ZM32.424 40.556L32.4228 41.0553C31.6273 41.0533 30.9836 40.4087 30.9855 39.615L32.424 40.556ZM32.424 40.556L32.4228 41.0553L39.3356 41.0719L39.3526 41.072L39.3529 41.072M32.424 40.556L39.3529 41.072M39.3529 41.072C39.3554 41.072 39.3577 41.072 39.3599 41.0719M39.3529 41.072L39.3599 41.0719M39.3599 41.0719C40.1528 41.0698 40.7939 40.4282 40.7967 39.6403L39.3599 41.0719ZM39.3545 41.0719C39.354 41.0719 39.3535 41.0719 39.3529 41.0719L39.3545 41.0719Z" fill="white" stroke="white"/>
				</svg>
			</span>
		</>
	)
}
function ArrowDown(){
	return(
		<>
			<span>
				<svg width="64" height="63" viewBox="0 0 64 63" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="0.0527344" width="63.1713" height="63" rx="31.5" fill="#F04444"/>
					<path d="M22.2454 30.9043L22.2454 30.9043L22.2287 23.9961C22.2286 23.9924 22.2286 23.989 22.2287 23.9858L22.2454 30.9043ZM22.2454 30.9043C22.2473 31.6978 22.8938 32.3396 23.6896 32.3377C24.4853 32.3358 25.1288 31.691 25.1269 30.8975L25.1269 30.8975L25.1186 27.4671L38.3382 40.6509C38.9008 41.212 39.813 41.212 40.3757 40.6509C40.9383 40.0897 40.9383 39.18 40.3757 38.6189L27.1561 25.4351M22.2454 30.9043L27.1561 25.4351M27.1561 25.4351L30.5957 25.4434L30.5957 25.4434C31.3914 25.4453 32.038 24.8036 32.0399 24.01L27.1561 25.4351ZM30.6014 23.069L30.6026 22.5697C31.3981 22.5717 32.0418 23.2163 32.0399 24.01L30.6014 23.069ZM30.6014 23.069L30.6026 22.5697L23.6898 22.5531L23.6728 22.553L23.6724 22.553M30.6014 23.069L23.6724 22.553M23.6724 22.553C23.67 22.553 23.6677 22.553 23.6655 22.5531M23.6724 22.553L23.6655 22.5531M23.6655 22.5531C22.8726 22.5552 22.2315 23.1968 22.2287 23.9847L23.6655 22.5531ZM23.6708 22.5531C23.6714 22.5531 23.6719 22.5531 23.6724 22.5531L23.6708 22.5531Z" fill="white" stroke="white"/>
				</svg>
			</span>
		</>
	)
}
function CoinIcon1(){
	return(
		<>
			<svg className="me-1" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
				  d="M12.001 0C5.37358 0 0.000976562 5.3726 0.000976562 12C0.000976562 18.6274 5.37358 24 12.001 24C18.6284 24 24.001 18.6274 24.001 12C23.9934 5.37574 18.6252 0.00758581 12.001 0ZM16.2867 18.0001H9.42964C8.95618 18.0001 8.57244 17.6164 8.57244 17.1429C8.57244 17.1024 8.57532 17.0618 8.58107 17.0216L9.22613 12.5054L7.9232 12.8313C7.85519 12.8486 7.78535 12.8572 7.71524 12.8572C7.24178 12.8567 6.85857 12.4727 6.85883 11.9992C6.85935 11.6063 7.12669 11.2642 7.50781 11.1684L9.48771 10.6735L10.2952 5.0213C10.3622 4.55254 10.7964 4.22714 11.2652 4.2941C11.7339 4.36107 12.0593 4.79529 11.9923 5.26404L11.2835 10.2247L14.3646 9.4543C14.8232 9.33737 15.2896 9.61439 15.4062 10.0729C15.5232 10.5315 15.2461 10.9979 14.7876 11.1148C14.785 11.1153 14.7824 11.1161 14.7797 11.1166L11.0214 12.0562L10.4174 16.2857H16.2867C16.7602 16.2857 17.1439 16.6695 17.1439 17.1429C17.1439 17.6161 16.7602 18.0001 16.2867 18.0001Z"
				  fill="#374C98"
				/>
			</svg>
			<span className="text-black">Ripple</span>
		</>
	)
}
function CoinIcon2(){
	return(
		<>
			<svg className="me-1" width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
				  d="M12.2176 0.00189099C5.59969 -0.114662 0.120182 5.17136 0.00359729 11.7875C-0.035493 13.869 0.464451 15.8373 1.37107 17.5623H5.14363V5.75207C5.14363 5.03013 6.12501 4.80045 6.4439 5.44835L12.0016 16.6998L17.5593 5.44903C17.8782 4.80045 18.8595 5.03013 18.8595 5.75207V17.5623H22.6204C23.464 15.9594 23.966 14.1494 23.9996 12.2194C24.1162 5.60329 18.8355 0.1253 12.2176 0.00189099Z"
				  fill="#FF782C"
				/>
				<path
				  d="M17.4879 18.2479V8.6892L12.6161 18.5516C12.3856 19.0199 11.6169 19.0199 11.3864 18.5516L6.51522 8.6892V18.2479C6.51522 18.6264 6.20867 18.9335 5.82943 18.9335H2.22832C4.36183 21.9303 7.83058 23.9241 11.7856 23.9981C15.8983 24.0708 19.5686 22.0551 21.7886 18.9335H18.1737C17.7945 18.9335 17.4879 18.6264 17.4879 18.2479Z"
				  fill="#FF782C"
				/>
			</svg>
			<span className="text-black">Ethereum</span>
		</>
	)
} 
function CoinIcon3(){
	return(
		<>
			<svg className="me-1" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.3811 13.8733C12.1369 13.9546 11.865 13.9546 11.6209 13.8733L9.00098 13L12.001 18L15.001 13L12.3811 13.8733Z" fill="#00ADA3"/>
				<path d="M12.001 12L15.001 10.8L12.001 5.99997L9.00098 10.8L12.001 12Z" fill="#00ADA3" />
				<path 
					d="M12.001 -3.05176e-05C5.37358 -3.05176e-05 0.000976562 5.37257 0.000976562 12C0.000976562 18.6274 5.37358 24 12.001 24C18.6284 24 24.001 18.6274 24.001 12C23.9937 	5.37571 18.6252 0.00729373 12.001 -3.05176e-05ZM17.0534 11.5262L12.7677 20.0977C12.556 20.5212 12.041 20.6928 11.6178 20.4812C11.4517 20.3983 11.3172 20.2638 11.2343 20.0977L6.94855 11.5262C6.81541 11.2589 6.83058 10.9416 6.98857 10.6881L11.2743 3.83108C11.5592 3.42981 12.115 3.33512 12.5163 3.61998C12.5982 3.67805 12.6696 3.7492 12.7276 3.83108L17.0131 10.6881C17.1714 10.9416 17.1865 11.2589 17.0534 11.5262Z"
				  fill="#00ADA3"
				/>
			  </svg>
			  <span className="text-black">David</span>
		</>
	)
}
function CoinIcon4(){
	return(
		<>
			<svg className="me-1" width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
				<path d="M16 9.50011C15.9993 8.67201 15.328 8.00092 14.5001 8H10V11H14.5001C15.328 10.9993 15.9993 10.328 16 9.50011Z" fill="#FFAB2D" />
				<path d="M10 16H14.5001C15.3285 16 16 15.3285 16 14.5001C16 13.6715 15.3285 13 14.5001 13H10V16Z" fill="#FFAB2D" />
				<path
				  d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0ZM18.0001 14.5713C17.9978 16.4641 16.4641 17.9978 14.5716 17.9999V18.8571C14.5716 19.3305 14.1876 19.7143 13.7144 19.7143C13.2409 19.7143 12.8572 19.3305 12.8572 18.8571V17.9999H11.1431V18.8571C11.1431 19.3305 10.7591 19.7143 10.2859 19.7143C9.8124 19.7143 9.42866 19.3305 9.42866 18.8571V17.9999H6.85733C6.38387 17.9999 6.00013 17.6161 6.00013 17.1429C6.00013 16.6695 6.38387 16.2857 6.85733 16.2857H7.71427V7.71427H6.85733C6.38387 7.71427 6.00013 7.33053 6.00013 6.85707C6.00013 6.38361 6.38387 5.99987 6.85733 5.99987H9.42866V5.14293C9.42866 4.66947 9.8124 4.28573 10.2859 4.28573C10.7593 4.28573 11.1431 4.66947 11.1431 5.14293V5.99987H12.8572V5.14293C12.8572 4.66947 13.2409 4.28573 13.7144 4.28573C14.1879 4.28573 14.5716 4.66947 14.5716 5.14293V5.99987C16.4571 5.99202 17.992 7.5139 18.0001 9.39937C18.0043 10.3978 17.5714 11.3481 16.8152 12C17.5643 12.6445 17.9967 13.5828 18.0001 14.5713Z"
				  fill="#FFAB2D"
				/>
			  </svg>
			  <span className="text-black">Bitcoin</span>
		</>
	)
}
function CoinIcon5(){
	return(
		<>
			<svg className="me-2" width={42} height={42} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M20.6335 23.1222C20.2266 23.2577 19.7734 23.2577 19.3665 23.1222L15 21.6666L20 30L25 21.6666L20.6335 23.1222Z" fill="#00ADA3"/>
				<path d="M20 20L25 18.0001L20 10L15 18.0001L20 20Z" fill="#00ADA3"/>
				<path
					d="M20 0C8.95433 0 0 8.95433 0 20C0 31.0457 8.95433 40 20 40C31.0457 40 40 31.0457 40 20C39.9878 8.95956 31.0404 0.0122071 20 0ZM28.4207 19.2105L21.2778 33.4962C20.9251 34.2021 20.0667 34.488 19.3613 34.1353C19.0845 33.9972 18.8604 33.7731 18.7222 33.4962L11.5793 19.2105C11.3574 18.7649 11.3827 18.2361 11.646 17.8136L18.7889 6.38517C19.2636 5.7164 20.1901 5.55858 20.8588 6.03335C20.9953 6.13013 21.1143 6.24872 21.2111 6.38517L28.3536 17.8136C28.6173 18.2361 28.6426 18.7649 28.4207 19.2105Z"
					fill="#00ADA3"
				/>
			</svg>
			<span className="text-black">Ethereum</span>
		</>
	)
} 
function CoinIcon6(){
	return(
		<>
			<svg className="me-2" width={42} height={42} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16 9.50011C15.9993 8.67201 15.328 8.00092 14.5001 8H10V11H14.5001C15.328 10.9993 15.9993 10.328 16 9.50011Z" fill="#FFAB2D"/>
				<path d="M10 16H14.5001C15.3285 16 16 15.3285 16 14.5001C16 13.6715 15.3285 13 14.5001 13H10V16Z" fill="#FFAB2D"/>
				<path
				  d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0ZM18.0001 14.5713C17.9978 16.4641 16.4641 17.9978 14.5716 17.9999V18.8571C14.5716 19.3305 14.1876 19.7143 13.7144 19.7143C13.2409 19.7143 12.8572 19.3305 12.8572 18.8571V17.9999H11.1431V18.8571C11.1431 19.3305 10.7591 19.7143 10.2859 19.7143C9.8124 19.7143 9.42866 19.3305 9.42866 18.8571V17.9999H6.85733C6.38387 17.9999 6.00013 17.6161 6.00013 17.1429C6.00013 16.6695 6.38387 16.2857 6.85733 16.2857H7.71427V7.71427H6.85733C6.38387 7.71427 6.00013 7.33053 6.00013 6.85707C6.00013 6.38361 6.38387 5.99987 6.85733 5.99987H9.42866V5.14293C9.42866 4.66947 9.8124 4.28573 10.2859 4.28573C10.7593 4.28573 11.1431 4.66947 11.1431 5.14293V5.99987H12.8572V5.14293C12.8572 4.66947 13.2409 4.28573 13.7144 4.28573C14.1879 4.28573 14.5716 4.66947 14.5716 5.14293V5.99987C16.4571 5.99202 17.992 7.5139 18.0001 9.39937C18.0043 10.3978 17.5714 11.3481 16.8152 12C17.5643 12.6445 17.9967 13.5828 18.0001 14.5713Z"
				  fill="#FFAB2D"
				/>
			  </svg>
			  <span className="text-black">Bitcoin</span>
		</>
	)
}
function CoinIcon7(){
	return(
		<>
			<svg className="me-2" width={42} height={42} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.2176 0.00189099C5.59969 -0.114662 0.120182 5.17136 0.00359729 11.7875C-0.035493 13.869 0.464451 15.8373 1.37107 17.5623H5.14363V5.75207C5.14363 5.03013 6.12501 4.80045 6.4439 5.44835L12.0016 16.6998L17.5593 5.44903C17.8782 4.80045 18.8595 5.03013 18.8595 5.75207V17.5623H22.6204C23.464 15.9594 23.966 14.1494 23.9996 12.2194C24.1162 5.60329 18.8355 0.1253 12.2176 0.00189099Z"
					fill="#FF782C"
				/>
				<path
					d="M17.4879 18.2479V8.6892L12.6161 18.5516C12.3856 19.0199 11.6169 19.0199 11.3864 18.5516L6.51522 8.6892V18.2479C6.51522 18.6264 6.20867 18.9335 5.82943 18.9335H2.22832C4.36183 21.9303 7.83058 23.9241 11.7856 23.9981C15.8983 24.0708 19.5686 22.0551 21.7886 18.9335H18.1737C17.7945 18.9335 17.4879 18.6264 17.4879 18.2479Z"
					fill="#FF782C"
				/>
			</svg>
			<span className="text-black">Monero</span>
		</>
	)
}
function CoinIcon8(){
	return(
		<>
			<svg className="me-2" width={42} height={42} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.001 0C5.37358 0 0.000976562 5.3726 0.000976562 12C0.000976562 18.6274 5.37358 24 12.001 24C18.6284 24 24.001 18.6274 24.001 12C23.9934 5.37574 18.6252 0.00758581 12.001 0ZM16.2867 18.0001H9.42964C8.95618 18.0001 8.57244 17.6164 8.57244 17.1429C8.57244 17.1024 8.57532 17.0618 8.58107 17.0216L9.22613 12.5054L7.9232 12.8313C7.85519 12.8486 7.78535 12.8572 7.71524 12.8572C7.24178 12.8567 6.85857 12.4727 6.85883 11.9992C6.85935 11.6063 7.12669 11.2642 7.50781 11.1684L9.48771 10.6735L10.2952 5.0213C10.3622 4.55254 10.7964 4.22714 11.2652 4.2941C11.7339 4.36107 12.0593 4.79529 11.9923 5.26404L11.2835 10.2247L14.3646 9.4543C14.8232 9.33737 15.2896 9.61439 15.4062 10.0729C15.5232 10.5315 15.2461 10.9979 14.7876 11.1148C14.785 11.1153 14.7824 11.1161 14.7797 11.1166L11.0214 12.0562L10.4174 16.2857H16.2867C16.7602 16.2857 17.1439 16.6695 17.1439 17.1429C17.1439 17.6161 16.7602 18.0001 16.2867 18.0001Z"
				  fill="#374C98"
				/>
			</svg>
			<span className="text-black">Litecoin</span>
		</>
	)
} 

export {ArrowUp,ArrowDown, CoinIcon1, CoinIcon2, CoinIcon3, CoinIcon4, CoinIcon5,CoinIcon6,CoinIcon7,CoinIcon8}; 
export default TableData;